@import url(https://fonts.googleapis.com/css2?family=Cabin&display=swap);
body {
    font-family: 'Cabin';
    background: #000000;
    white: #ffffff;
    overflow: hidden;
}

main {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: space-between;
    height: 90vh;
    width: 100vw;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.times {
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.M {
    font-size: 2.5vw;
}
.XL {
    font-size: 5.5vw;
}
